import React, { useEffect, useState } from "react"

import Layout from "../../layouts"
import { Box, Flex, Text } from "theme-ui"
import Medusa from "../../../services/api"
import { WishListItems } from "./wishlist"
import useHasMounted from "../../../hooks/useHasMounted"
import { isEmpty } from "lodash-es"
import LoadingSpinner from "../../ui/LoadingSpinner"

const SharedWishlist = () => {
  const [loading, setLoading] = useState(true)
  const [wishlist, setWishlist] = useState({})
  const hasMounted = useHasMounted()

  useEffect(() => {
    const getWishlist = async (token) => {
      Medusa.wishlist
        .retreiveCustomerWithToken(token)
        .then(({ data }) => {
          return data.wishlist
        })
        .then((wishlist) => {
          return Medusa.variants
            .list({
              ids: wishlist.items.map((wish) => wish.variant_id).join(","),
            })
            .then(({ data }) => {
              const items = data.variants
                .map((variant) => {
                  // Append the metadata from accountcontext items
                  const metadataItem = wishlist.items.find(
                    (item) => item.variant_id === variant.id
                  )

                  return {
                    ...variant,
                    thumbnail: metadataItem.thumbnail,
                    metadata: metadataItem.metadata,
                    title: metadataItem.title,
                    description: metadataItem.description,
                  }
                })
                .reduce((acc, next) => {
                  const metadata = next.metadata
                  if (metadata?.product_bundle_id) {
                    const bundle = acc.find(
                      (item) => item.unique_id === metadata.unique_id
                    )

                    if (!bundle) {
                      acc.push({
                        ...bundle,
                        unique_id: metadata.unique_id,
                        thumbnail: metadata.bundle_thumbnail,
                        metadata: {
                          product_handle: metadata.product_handle,
                          collection_slug: metadata.collection_slug,
                        },
                        title: metadata.bundle_title,
                        id: next.id,
                        bundle_url: `${metadata.collection_slug}/${metadata.product_handle}`,
                        description: next.description,
                        items: [next],
                      })
                    } else {
                      bundle.items = [...bundle.items, next]
                      bundle.description = `${bundle.description} & ${next.description}`
                    }
                  } else {
                    acc.push(next)
                  }

                  return acc
                }, [])

              return { items: items, first_name: wishlist.first_name }
            })
        })
        .then((wishlist) => {
          setWishlist(wishlist)
          setLoading(false)
        })
        .catch((err) => {
          setLoading(false)
          console.error(err)
        })
    }
    if (hasMounted) {
      const urlSearchParams = new URLSearchParams(window.location.search)
      const params = Object.fromEntries(urlSearchParams.entries())

      getWishlist(params.jwt)
    }
  }, [hasMounted])

  if (loading) {
    return (
      <Layout hideFooter={true}>
        <LoadingSpinner />
      </Layout>
    )
  }
  return (
    <Layout>
      {!isEmpty(wishlist) ? (
        <Box sx={{ width: "100%", paddingX: [2, "5%"], my: 4 }}>
          <Flex
            sx={{ justifyContent: "space-between", alignItems: "flex-end" }}
          >
            <Text sx={{ fontSize: "21px" }}>
              {`${wishlist.first_name}'s whishlist`}
            </Text>
          </Flex>
          <WishListItems
            items={wishlist?.items || []}
            sx={{
              ".item": {
                flex: ["1 0 45%", "1 0 20%"],
                maxWidth: ["50%", "25%"],
              },
            }}
          />
        </Box>
      ) : (
        <Box sx={{ width: "100%", paddingX: [2, "5%"], my: 4 }}>
          <Flex
            sx={{ justifyContent: "center", alignItems: "flex-end", flex: 1 }}
          >
            <Text sx={{ fontSize: "21px" }}>
              Couldn't load the wishlist. Try with a new link
            </Text>
          </Flex>
        </Box>
      )}
    </Layout>
  )
}

export default SharedWishlist
